@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
/*@import-normalize;*/
html, body {
  height: 100%;
  min-width: 320px;
  overflow-x: hidden;
  /*overflow-y: scroll;*/
  margin: 0;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  font-family: 'Roboto', "Helvetica Neue", Helvetica, Arial, sans-serif;;
  /*font-family: "Signika", "Helvetica Neue", Helvetica, Arial, sans-serif;*/
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-width: 320px;
  display: flex;
  flex-direction: column;
  position:absolute;
  /*height:auto;*/
  bottom:0;
  top:0;
  left:0;
  right:0;
  /* IOS webapp full screen insets*/
  margin: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
